import { Link } from 'gatsby';
import React from 'react';
import ChevronRight from 'src/assets/img/ic_chevron_right.svg';
import ListCard from 'src/components/gift-list/list-card';
import Layout from 'src/components/layout/layout';
import FeedProductList from 'src/components/list/list-feed';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import DesktopComponent from 'src/hocs/desktop-component';
import useTranslation from 'src/hooks/useTranslation';
import { productsQuery } from 'src/queries';
import { contentfulClient } from 'src/util/contentful-client';
import { StaticContextType } from 'typings/custom';
import {
  ICustomGiftlist,
  ICustomGiftlistWrapper,
} from 'typings/generated/contentful';

type Props = {
  pageContext: {
    reactContext: StaticContextType;
    giftLists: ICustomGiftlist[];
  };
};

const IndexPage = ({ pageContext }: Props) => {
  const { reactContext, giftLists } = pageContext;
  const t = useTranslation();
  const giftListWrapper: ICustomGiftlistWrapper =
    reactContext.customGiftlistWrapper;

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    (window as any).client = contentfulClient;
  }

  return (
    <Layout context={reactContext}>
      <SEO />
      <div className="flex flex-col items-center">
        <Container>
          <DesktopComponent>
            <div className="grid grid-cols-4 gap-4">
              {giftLists.slice(0, 4).map((giftList) => (
                <ListCard
                  assetWidth={180}
                  giftList={giftList}
                  key={giftList.sys.id}
                />
              ))}
            </div>
            <div className="flex justify-between pt-4 pb-8 space-x-1 title">
              <a
                className="hover:underline"
                href="https://www.reviewhero.io/krass-geil"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                {t('customerReviews')}
              </a>
              <Link to={`/${giftListWrapper.fields.slug}`}>
                <div className="flex text-x-orange">
                  <span className="pr-1">{t('morePresentGuides')}</span>
                  <span className="transform scale-75 text-x-red">
                    <ChevronRight className="h-4" />
                  </span>
                </div>
              </Link>
            </div>
          </DesktopComponent>
        </Container>
        <Container loose>
          <FeedProductList
            filter={() => true}
            giftLists={giftLists}
            query={productsQuery()}
          />
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
